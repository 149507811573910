import React from "react";
import classNames from 'classnames';
import './SongCard.css';

export default function SongCard({ song: { title, year, spotifyURI, thumbnail } }) {
    return (
        <div className={classNames('song-card')}>
            <div className={classNames('song-card-thumbnail')}>
                <img src={thumbnail} alt={title} />
                <div className={classNames('song-card-year flex-center')}>{year}</div>
            </div>
            <div className={classNames('song-card-content')}>
                <div className={classNames('song-card-title')}>
                    {title}
                </div>
                <div className={classNames('song')}>
                    <iframe
                        style={{ borderRadius: 12 }}
                        src={`https://open.spotify.com/embed/track/${spotifyURI}?utm_source=generator`}
                        width="100%"
                        height="80"
                        frameBorder="0"
                        allowfullscreen=""
                        allow="autoplay; clipboard-write; fullscreen; picture-in-picture">
                    </iframe>
                </div>
            </div>
        </div >
    )
}