import React from 'react';
import classNames from 'classnames';
import {
    motion
} from "framer-motion";

import DiscographyTitle from './../../assets/images/svg/Discografia-Titulo.svg';
import SongCard from '../../components/SongCard/SongCard';
import { songs } from '../../util/songs';
import './DiscographyPage.css';
import PromoCard from '../../components/Promo/PromoCard';
import Footer from '../../components/Footer/Footer';

export default function DiscographyPage({ child }) {

    return (
        <motion.section id='discographyPage'
            initial={{ display: 'none', opacity: 0 }}
            animate={{ display: 'block', opacity: 1 }}
            exit={{ display: 'none', opacity: 0 }}
            transition={{ type: "spring", duration: 2 }}
        >
            <div className={classNames('section-title flex-center')}>
                <img src={DiscographyTitle} />
            </div>
            <div className={classNames('songs-container')}>
                <PromoCard />
                <div className={classNames('song-cards-container')}>
                    {
                        songs.map((song, index) => {
                            return <SongCard index={index} song={song} />
                        })
                    }
                    <Footer />
                </div>
            </div>
        </motion.section>
    );
}

