import React from "react";
import {
  BrowserRouter as Router,
} from "react-router-dom";
import AnimatedRoutes from "./pages/AnimatedRoutes";
import HomePage from "./pages/HomePage/HomePage";

import './styles.css';

export default function App() {
  return (
    <Router>
      <div id="mainContainer">
        <HomePage
          child={
            <AnimatedRoutes />
          }>
        </HomePage>
      </div>
    </Router>
  );
}
