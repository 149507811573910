import React from "react";
import {
    Route,
    Routes,
    Navigate,
    useLocation
} from "react-router-dom";
import {
    AnimatePresence
} from "framer-motion";
import BiographyPage from "./Biography/BiographyPage";
import DiscographyPage from "./Discography/DiscographyPage";
import ContactPage from "./Contact/ContactPage";

export default function AnimatedRoutes() {
    const location = useLocation();
    return (
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                <Route path="/" element={<Navigate replace to="/discography" />} />
                <Route path="/discography" element={<DiscographyPage />} />
                <Route path="/biography" element={<BiographyPage />} />
                <Route path="/contact" element={<ContactPage />} />
            </Routes>
        </AnimatePresence>
    );
}