import React from 'react';
import './PromoCard.css';


export default function PromoCard() {
    return (
        <div className='promo-card'>
            <div className='promo-card-image'>
            </div>
            <div className='promo-card-content'>
                <div className='promo-card-song-title'>
                    Carezco de ti
                </div>
                <div className='promo-card-date'>
                    Julio 22
                </div>
            </div>
        </div>
    )
}