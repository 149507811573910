import React from 'react';
import classNames from 'classnames';
import {
    Link,
    useLocation
} from "react-router-dom";
import Logo from "../../assets/images/svg/Magy-Robel-Logo.svg";
import './HomePage.css';


export default function HomePage({ child }) {

    const location = useLocation();

    const scrollToPage = () => {
        const element = document.getElementById('mainContent');
        let pos = element.offsetTop;
        window.scroll({
            top: pos,
            behavior: 'smooth'
        });
    }

    return (
        <div id='homePage'>
            <div className='left-container flex-center'>
                <div className='logo-container flex-center-column'>
                    <img src={Logo} alt="Magy Robel Logo" />
                    <div className='tags-container'>
                        <span className='tag'>
                            Cantante
                        </span>
                        <span className='tag'>
                            Compositora
                        </span>
                        <span className='tag'>
                            Productora Musical
                        </span>
                    </div>
                </div>
                <nav className='flex-center'>
                    <div>
                    </div>
                    <ul>
                        <li
                            onClick={scrollToPage}
                            className={classNames({ 'active': location.pathname.includes('discography') })}>
                            <Link to="/discography">Discografía</Link>
                        </li>
                        <li
                            onClick={scrollToPage}
                            className={classNames({ 'active': location.pathname.includes('contact') })}>
                            <Link to="/contact">Contacto</Link>
                        </li>
                    </ul>
                </nav>
            </div>
            <div id='mainContent' className='right-container'>
                {child}
            </div>
        </div>
    );
}