import React from 'react';
import classNames from 'classnames';
import {
    motion
} from "framer-motion";
import './ContactPage.css';
import ContactTitle from './../../assets/images/svg/Contacto-Titulo.svg';
import Footer from '../../components/Footer/Footer';


export default function ContactPage({ child }) {


    return (
        <motion.section id='contactPage'
            initial={{ display: 'none', opacity: 0 }}
            animate={{ display: 'flex', opacity: 1 }}
            exit={{ display: 'none', opacity: 0 }}
            transition={{ type: "spring", duration: 2 }}
        >
            <div className={classNames('section-title flex-center')}>
                <img src={ContactTitle} />
            </div>
            <div className={classNames('contact-content flex-center')}>
                <div className='contact-title'>Contratación</div>
                <div className='contact-description'><a href="mailto: management@magyrobel.com" target={'_blank'}>management@magyrobel.com</a></div>
                <div className='contact-title'>Management</div>
                <div className='contact-description'><a href="https://wa.link/ekdrhz" target={'_blank'}>{'(+57) 318 314 7984'}</a></div>
            </div>
            <Footer />
        </motion.section>
    );
}