import React from 'react';
import classNames from 'classnames';
import {
    useLocation
} from "react-router-dom";
import {
    motion
} from "framer-motion";

import BiographyTitle from './../../assets/images/svg/Biografia-Titulo.svg';


export default function BiographyPage({ child }) {

    const location = useLocation();

    return (
        <motion.section id='biographyPage'
            initial={{ opacity: 0, display: 'none' }}
            animate={{ opacity: 1, display: 'block' }}
            exit={{ opacity: 0, display: 'none' }}
        >
            <div className={classNames('section-title flex-center')}>
                <img src={BiographyTitle} />
            </div>
        </motion.section>
    );
}