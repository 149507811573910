import NoLaRepitasQueNoQuiero from './../assets/images/thumbs/no-la-repitas-que-no-quiero.jpg';
import EsaSonrisa from './../assets/images/thumbs/esa-sonrisa.jpg';
import LaExcepcion from './../assets/images/thumbs/la-excepcion.jpg';


const songs = [
    {
        title: 'La excepción',
        year: 2022,
        youtubeURI: 'mAIgtJXdzAk',
        spotifyURI: '7v0w6l6RaGLiVFIzCU9tpM',
        thumbnail: LaExcepcion,
    }, {
        title: 'Esa sonrisa',
        year: 2022,
        youtubeURI: 'mAIgtJXdzAk',
        spotifyURI: '02qWFgtmY7zCQtp69b24sU',
        thumbnail: EsaSonrisa,
    }, {
        title: 'Un nuevo amanecer',
        year: 2020,
        youtubeURI: 'mAIgtJXdzAk',
        spotifyURI: '4GpjsgBUpT5Y285G0L6Iai',
        thumbnail: NoLaRepitasQueNoQuiero,
    }, {
        title: 'No la repitas que no quiero',
        year: 2020,
        youtubeURI: 'mAIgtJXdzAk',
        spotifyURI: '0gILNkd627jGRiMXUjh8sm',
        thumbnail: NoLaRepitasQueNoQuiero,
    }
]

export { songs };